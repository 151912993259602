class basket {

    public _baskettoggle: JQuery;
    public _basket: JQuery;
    public _navigation: JQuery;
    public _navtoggle: JQuery;
    constructor() {
        this._basket = $('.basket-modal');
        this._baskettoggle = $('.nav-cart');
        this._navigation = $('.header');
        this._navtoggle = $('.nav-toggle');
        this.addEventListener();
    }

    addEventListener() {
        this._baskettoggle.on('click', event => this.openBasket());
        this._navtoggle.on('click', event => this.togglegNav());
    }
    openBasket() {
        this._basket.toggleClass('open');
    }

    togglegNav() {
        this._navtoggle.toggleClass('open');
        this._navigation.toggleClass('open');
    }

    breadcrumb(){
        window.history.go(-1);
    }

}

const shoppingbasket = new basket();
